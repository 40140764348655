<script lang="ts">
  import GithubLogo from "$lib/assets/logos/socials/GithubLogo.svelte";
  import LinkedinLogo from "$lib/assets/logos/socials/LinkedinLogo.svelte";
  import SlackLogo from "$lib/assets/logos/socials/SlackLogo.svelte";
  import XLogo from "$lib/assets/logos/socials/XLogo.svelte";
  import YoutubeLogo from "$lib/assets/logos/socials/YoutubeLogo.svelte";
</script>

<div class="flex items-center gap-5 flex-wrap">
  <a
    class="social-btn"
    target="_blank"
    rel="noreferrer"
    href="https://x.com/modal_labs"
  >
    <XLogo size={20} />
  </a>
  <a
    class="social-btn"
    target="_blank"
    rel="noreferrer"
    href="https://www.linkedin.com/company/modal-labs/"
  >
    <LinkedinLogo size={17} />
  </a>
  <a
    class="social-btn"
    target="_blank"
    rel="noreferrer"
    href="https://modal.com/slack"
  >
    <SlackLogo size={22} />
  </a>
  <a
    class="social-btn"
    target="_blank"
    rel="noreferrer"
    href="https://github.com/modal-labs"
  >
    <GithubLogo size={26} />
  </a>
  <a
    class="social-btn"
    target="_blank"
    rel="noreferrer"
    href="https://www.youtube.com/channel/UC477UdoLR2Js3RHhRWSXsQA"
  >
    <YoutubeLogo size={22} />
  </a>

  <p class="text-sm text-light-green/60 whitespace-nowrap">&copy; Modal 2024</p>
</div>

<style lang="postcss">
  .social-btn {
    @apply border border-light-green/30 hover:bg-light-green/10 transition-colors;
    @apply w-10 h-10 shrink-0 rounded-full flex items-center justify-center fill-light-green;
  }
</style>
