<script lang="ts">
  import { page } from "$app/stores";

  import { analytics } from "$lib/analytics";
  import MarketingButton from "./MarketingButton.svelte";
  import VideoBackground from "../common/VideoBackground.svelte";
</script>

<section class="marketing-container my-24 md:my-40">
  <div class="grid md:grid-cols-2 gap-x-5 gap-y-10">
    <VideoBackground poster="https://modal-cdn.com/tmpk5brmwoa_0b5eb343.webp">
      <source
        src="https://modal-cdn.com/landscape-vids/Modal_CTA.mp4"
        type="video/mp4"
      />
    </VideoBackground>
    <div class="flex flex-col justify-center items-center md:items-start">
      <h2 class="marketing-h2 mb-7 md:mb-14 text-center md:text-left max-w-lg">
        Ship your first app in <span class="text-brand-green">minutes.</span>
      </h2>
      <MarketingButton
        href="/signup"
        variant="primary"
        on:click={() => {
          analytics.track("Button Clicked", {
            text: "Get Started",
            url_path: $page.url.pathname,
          });
        }}
      >
        Get Started
      </MarketingButton>
      <p class="mt-6 text-sm text-light-green/60">$30 / month free compute</p>
    </div>
  </div>
</section>
