<script lang="ts">
  import CtaSection from "$lib/ui/marketing/CtaSection.svelte";
  import MarketingFooter from "./MarketingFooter.svelte";
  import MarketingNavbar from "./MarketingNavbar.svelte";
</script>

<svelte:head>
  <style lang="postcss">
    body {
      @apply !bg-black !text-light-green;
    }
  </style>
</svelte:head>

<div class="min-h-screen">
  <MarketingNavbar />
  <slot />
  <CtaSection />
  <MarketingFooter />
</div>
