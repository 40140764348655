<script lang="ts">
  import { ArrowUpRight } from "lucide-svelte";
  import { createEventDispatcher } from "svelte";
  import { cubicOut } from "svelte/easing";
  import { slide } from "svelte/transition";

  const dispatch = createEventDispatcher();

  function close() {
    dispatch("close");
  }
</script>

<div
  class="absolute left-0 top-6 w-full bg-dark-gray shadow-lg -z-[1] rounded-b-xl overflow-hidden pt-6"
  transition:slide={{ duration: 300, axis: "y", easing: cubicOut }}
>
  <div class="w-full p-4 flex flex-row gap-2.5">
    <div
      class="basis-52 use-case-card bg-light-green/5 border-light-green/10 flex flex-row gap-x-8"
    >
      <div class="space-y-3 grow">
        <p class="text-sm text-light-green">Inference</p>
        <p class="text-xs text-light-green/60">
          Easily deploy and scale inference pipelines for LLMs, audio
          processing, and image/video generation
        </p>
      </div>
      <div class="shrink-0 flex flex-col gap-y-2.5 items-start">
        <a
          href="/use-cases/language-models"
          on:click={close}
          class="use-case-button text-light-green bg-light-green/10 border-light-green/20 hover:text-muted-yellow hover:bg-muted-yellow/10 hover:border-muted-yellow"
        >
          Language
          <ArrowUpRight
            size={24}
            class="hidden lg:inline rounded-full p-1 bg-muted-yellow stroke-dark-gray"
          />
        </a>
        <a
          href="/use-cases/image-video-3d"
          on:click={close}
          class="use-case-button text-light-green bg-light-green/10 border-light-green/20 hover:text-brand-green hover:bg-brand-green/10 hover:border-brand-green"
        >
          Image, Video & 3D
          <ArrowUpRight
            size={24}
            class="hidden lg:inline rounded-full p-1 bg-brand-green stroke-dark-gray"
          />
        </a>
        <a
          href="/use-cases/audio"
          on:click={close}
          class="use-case-button text-light-green bg-light-green/10 border-light-green/20 hover:text-bright-blue hover:bg-bright-blue/10 hover:border-bright-blue"
        >
          Audio
          <ArrowUpRight
            size={24}
            class="hidden lg:inline rounded-full p-1 bg-bright-blue stroke-dark-gray"
          />
        </a>
      </div>
    </div>
    <a
      href="/use-cases/fine-tuning"
      on:click={close}
      class="basis-0 use-case-card group bg-light-green/5 border-light-green/10 hover:bg-brand-green/10 hover:border-brand-green"
    >
      <p
        class="mb-3 xl:w-3/4 text-sm text-light-green group-hover:text-brand-green"
      >
        Fine-tuning
      </p>
      <p class="lg:w-3/4 text-xs text-light-green/60">
        Use your own data to fine-tune open-source models on serverless GPUs
      </p>
      <img
        src="https://modal-cdn.com/navbar-use-case-fine-tuning.webp"
        alt="Fine-tuning use case"
        class="hidden lg:block opacity-60 absolute left-[70%] -bottom-1 max-w-[90px] max-h-[90px] object-contain"
      />
    </a>
    <a
      href="/use-cases/job-queues"
      on:click={close}
      class="basis-0 use-case-card group bg-light-green/5 border-light-green/10 hover:bg-brand-green/10 hover:border-brand-green"
    >
      <p
        class="mb-3 xl:w-3/4 text-sm text-light-green group-hover:text-brand-green"
      >
        Job Queues & Batch Processing
      </p>
      <p class="lg:w-3/4 text-xs text-light-green/60">
        Orchestrate thousands of infinitely scalable containers on a schedule
      </p>
      <img
        src="https://modal-cdn.com/navbar-use-case-job-queues.webp"
        alt="Job queues use case"
        class="hidden lg:block opacity-60 absolute left-3/4 -bottom-2 max-w-[100px] max-h-[100px] object-contain"
      />
    </a>
    <a
      href="/use-cases/sandboxes"
      on:click={close}
      class="basis-0 use-case-card group bg-light-green/5 border-light-green/10 hover:bg-brand-green/10 hover:border-brand-green"
    >
      <p
        class="mb-3 xl:w-3/4 text-sm text-light-green group-hover:text-brand-green"
      >
        Sandboxed Code Execution
      </p>
      <p class="lg:w-3/4 text-xs text-light-green/60">
        Dynamically define compute tasks and run them in a secure environment
      </p>
      <img
        src="https://modal-cdn.com/navbar-use-case-sandboxes.webp"
        alt="Sandbox use case"
        class="hidden lg:block opacity-60 absolute left-3/4 -bottom-6 max-w-[120px] max-h-[120px] object-contain"
      />
    </a>
  </div>
</div>

<style lang="postcss">
  .use-case-card {
    @apply grow border rounded-lg p-3 relative overflow-hidden;
  }

  .use-case-button {
    @apply px-3 py-1 flex flex-row items-center gap-2.5 text-sm border rounded-full;
  }
</style>
